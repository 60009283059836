// authSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  role: "",
  entity: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      console.log("thisistheload", action.payload);
      state.isAuthenticated = true;
      state.role = action.payload.role;
      state.entity = action.payload.entity;

      const tokenWithPrefix = `Bearer ${action.payload.token}`;
      localStorage.setItem("token", tokenWithPrefix);
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.role = "";
      state.entity = null;
      localStorage.removeItem("token");
    },

    profileupload: (state, action) => {
      state.entity = action.payload.entity;
    },
  },
});

export const { loginSuccess, logout, profileupload } = authSlice.actions;
export default authSlice.reducer;
