// store.js

import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";

// Load state from session storage
const loadStateFromSessionStorage = () => {
  try {
    const serializedState = localStorage.getItem("authState");
    if (serializedState) {
      return JSON.parse(serializedState);
    }
  } catch (err) {
    console.error("Failed to load state from session storage", err);
  }
  return undefined;
};

const store = configureStore({
  reducer: {
    auth: authReducer,
  },
  preloadedState: loadStateFromSessionStorage(),
});

// Subscribe to store updates and save to session storage
store.subscribe(() => {
  try {
    const state = store.getState();
    localStorage.setItem("authState", JSON.stringify(state));
  } catch (err) {
    console.error("Failed to save state to session storage", err);
  }
});

export default store;
