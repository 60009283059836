import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ component: Component, requiredRole, ...rest }) => {
  const auth = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth.entity) {
          return <Redirect to={"/"} />;
        }
        if (auth.role !== requiredRole) {
          return <Redirect to={auth.role === "user" ? "/user" : "/org"} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
