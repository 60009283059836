import React, { lazy } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ProtectedRoute from "./Authorization/ProtectedRoute";

// css for making phone input "phone" dark mode compat
import "../src/assets/css/custom.css";
import Test from "./pages/Test";

const Layout = lazy(() => import("./containers/Layout"));
const LayoutOrg = lazy(() => import("./containers/LayoutOrg"));
const OrgLogin = lazy(() => import("./pages/org/Login"));
const UserLogin = lazy(() => import("./pages/user/Login"));
const CreateOrgAccount = lazy(() => import("./pages/org/CreateAccount"));
const CreateUserAccount = lazy(() => import("./pages/user/CreateAccount"));
const ForgotPasswordOrg = lazy(() => import("./pages/org/ForgotPasswordOrg"));
const ForgotPasswordUser = lazy(() =>
  import("./pages/user/ForgotPasswordUser")
);
const ResetPasswordOrg = lazy(() => import("./pages/org/ResetPassword"));
const ResetPasswordUser = lazy(() => import("./pages/user/ResetPassword"));

const OrgOTP = lazy(() => import("./pages/org/OtpPage"));
const UserOtp = lazy(() => import("./pages/user/OtpPage"));
const NotFound = lazy(() => import("./pages/404"));
const HomePage = lazy(() => import("./pages/HomePage"));
const HomePageEvents = lazy(() => import("./pages/EventListingHomePage"));

function App() {
  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/login/org" component={OrgLogin} />
          <Route path="/register/org" component={CreateOrgAccount} />
          <Route path="/forgot-password/org" component={ForgotPasswordOrg} />
          <Route
            path="/reset-password/org/:slug"
            component={ResetPasswordOrg}
          />
          <Route path="/otp/org" component={OrgOTP} />

          <Route path="/login/user" component={UserLogin} />
          <Route path="/register/user" component={CreateUserAccount} />
          <Route path="/forgot-password/user" component={ForgotPasswordUser} />
          <Route
            path="/reset-password/user/:slug"
            component={ResetPasswordUser}
          />
          <Route path="/otp/user" component={UserOtp} />

          {/* Place new routes over this */}
          {/* <Route path="/app" component={Layout} /> */}
          <ProtectedRoute
            path="/org"
            component={LayoutOrg}
            requiredRole="org"
          />
          <ProtectedRoute path="/user" component={Layout} requiredRole="user" />
          {/* If you have an index page, you can remothis Redirect */}

          {/* <Route exact path="/" component={HomePage} /> */}
          <Route exact path="/" component={HomePageEvents} />

          <Route path="*" component={NotFound} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
